@import url(~slick-carousel/slick/slick.css);
@import url(~slick-carousel/slick/slick-theme.css);
html {
  font-size: 14px;
}
body {
  color: #616161;
  height: 100%;
  overflow: auto;
  background: #fafafa;
  font-family: "sofia-pro";
  font-weight: 300;
}
input::placeholder,
textarea::placeholder {
  color: #c5c5c5;
  font-weight: 300;
}
audio {
  width: 100%;
}
.container-fluid {
  padding: 0;
  margin: 0;
}
.container-fluid .row {
  margin: auto;
  max-width: 1200px;
}
.container-fluid .row .row {
  max-width: 100%;
  width: 100%;
}
h1,
h2,
h3,
h4,
h5 {
  margin-top: 0;
  color: #424242;
  font-family: "sofia-pro";
  font-weight: 500;
  letter-spacing: -0.4px;
  margin-bottom: 30px;
}
.transition,
.clients .note-item button {
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  -ms-transition: all 250ms ease-in-out;
  -o-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
}
a {
  text-decoration: none !important;
}
img {
  max-width: 100%;
}
table * {
  border-color: #f3f3f3 !important;
}
table td,
table th {
  padding: 14px 20px !important;
}
a {
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.app-container {
  width: 100%;
  padding-top: 30px;
}
.app-container > .row {
  padding-left: 20px;
  padding-right: 20px;
}
hr {
  margin-top: 40px;
  margin-bottom: 40px;
}
.meta-nav {
  position: relative;
  overflow: auto;
  margin-bottom: 30px;
}
.meta-nav span {
  display: inline-block;
}
.meta-nav .is-left {
  width: 50%;
  float: left;
}
.meta-nav .is-left * + * {
  margin-left: 14px;
}
.meta-nav .is-right {
  text-align: right;
  width: 50%;
  float: left;
}
.meta-nav .is-right * + * {
  margin-left: 14px;
}
.meta-nav .btn-secondary {
  color: #616161 !important;
  background: #eaeaea !important;
}
.meta-nav select {
  border: none;
  background-color: #fff;
  box-shadow: 0px 3px 9px -3px #eaeaea;
  min-width: 100px;
}
.meta-nav .form-control {
  width: auto;
  display: inline-block;
}
.custom-height-width {
  height: 40px;
  width: 400px !important;
}
.registrationContainer {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(-223deg, #a776f6 0%, #7332e4 100%);
}
.registration_input {
  display: inline-block;
  width: 48%;
}
.checkout {
  background: white;
  width: 470px;
  padding: 15px;
  border-radius: 12px;
}
.checkout input {
  font-size: 16px;
}
.btn-purple {
  background: #9964f2;
  color: white;
  margin: 5px 0 0 0;
}
#card-element {
  padding: 2px 0 2px 0;
  margin: 5px 0 10px 0;
  border: 1px solid gainsboro;
  box-sizing: border-box;
}
.input-location-style {
  width: 120px;
  display: inline-block;
}
.state-selection-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
}
.state-selection-container .state-selection {
  border-radius: 0.25rem;
  min-width: 0;
  width: 140px;
  margin-left: 0;
}
.city-width {
  width: 235px;
}
.update-width {
  width: 335px;
}
.user-info-container {
  padding: 20px;
  background: white;
}
.StripeElement {
  padding: 8px;
}
.info-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.update-payment-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(-223deg, #a776f6 0%, #7332e4 100%);
}
.stripe-input-error {
  color: red;
  font-weight: bold;
}
.recaptcha-container {
  margin: 10px 0 10px 0;
}
.subscription-expired-container {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 1.8em;
}
.check-out {
  position: absolute;
  bottom: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
  font-size: 2rem;
  color: #fff;
}
.mbn-10 {
  margin-bottom: -10px;
}
.mbn-20 {
  margin-bottom: -20px;
}
.mbn-25 {
  margin-bottom: -25px;
}
.mbn-30 {
  margin-bottom: -30px;
}
.toggle-comp {
  position: relative;
  margin-left: 20px;
  margin-right: 20px;
}
.toggle-comp_label {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.5px;
  display: block;
  position: absolute;
  right: calc(100% + 10px);
  top: 50%;
  white-space: nowrap;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.toggle-comp:hover .toggle-comp_toggle {
  background: #c9c9c9;
}
.toggle-comp.is-active .toggle-comp_toggle {
  background: #619bf9;
}
.toggle-comp.is-active .toggle-comp_toggle:after {
  left: calc(50% + 2px) !important;
}
.toggle-comp_toggle {
  display: inline-block;
  height: 24px;
  width: 44px;
  background-color: #dadada;
  border-radius: 30px;
  position: relative;
  top: 4px;
}
.toggle-comp_toggle:after {
  transition: all 250ms ease-in-out;
  border-radius: 30px;
  content: " ";
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: #fff;
  display: block;
  top: 50%;
  left: 4px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.route-locations .qr-code {
  width: 128px;
  height: 128px;
  margin-top: 16px;
  box-shadow: 0px 3px 9px -3px #eaeaea;
  border-radius: 3px;
}
.route-locations a {
  cursor: pointer;
}
.route-locations .shareable-link-wrapper {
  position: relative;
  border-radius: 3px;
}
.route-locations .shareable-link-wrapper:after {
  transition: all 0.4s;
  background: #9964f200;
  color: white;
  content: "Copy to Clipboard";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 144px;
  text-align: center;
  background: #9964f2;
  font-weight: 300;
  line-height: 33.5px;
  cursor: pointer;
  border-radius: 0 3px 3px 0;
}
.route-locations .shareable-link-wrapper input {
  width: 100%;
  height: 36px;
  padding-left: 12px;
  background: white;
  border: none;
  box-shadow: 0px 3px 9px -3px #eaeaea;
  position: relative;
  border-radius: 3px;
}
.route-locations .shareable-link-wrapper input:focus {
  border: none;
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.login-input-container input {
  font-size: 16px;
}
.settings-for-review-widget .toggle-comp {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 0;
  margin-top: 25px;
}
.settings-for-review-widget .toggle-comp_label {
  position: relative;
  right: 0;
  top: 0;
  transform: 0;
  transform: translateY(0);
  margin-right: 10px;
}
.settings-for-review-widget .toggle-comp_toggle {
  top: 0;
}
.center-me {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.center-me span {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.black-text {
  color: black;
}
.grey-bg {
  background: #f8f8f8 !important;
}
.autocomplete-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 30px;
}
.autocomplete-container .autocomplete-dropdown-container > div {
  background: #fff;
  padding: 8px;
  margin-top: 16px;
  box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.1);
}
.autocomplete-container .reviews-font-headline {
  color: black;
  font-size: 1.2em;
  font-weight: bold;
}
.location-search-input {
  width: 400px;
  height: 35px;
  margin: auto;
}
.selection-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.reviews-container {
  position: relative;
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.reviews-container .reviews-title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  color: black;
}
.reviews-container .reviews-title-description {
  display: flex;
  justify-content: center;
  align-items: center;
}
.reviews-container .reviews-font-p {
  text-align: center;
  font-size: 15px;
  color: black;
}
.reviews-container .review-try-now-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.reviews-container .review-try-now-container .reviews-button {
  background-color: #9964f2;
  width: 158px;
  height: 44px;
  color: white;
  border-radius: 10px;
}
.reviews-container .review-try-now-container .try-now-snippet {
  font-size: 23px;
}
.reviews-container .z-index-2 {
  z-index: 2;
}
.selection-info {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 310px;
  height: 130px;
  background: #fff;
  box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.1);
  margin: 8px;
}
.selection-info .yelp-selection-info-container {
  width: 80%;
  margin-left: 18px;
  margin-top: 10px;
}
.selection-info .yelp-selection-info-container h2 {
  font-size: 1.1em;
  color: black;
}
.selection-info .yelp-selection-button-container {
  margin-right: 18px;
}
.reviews-title-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 1.1em;
  color: black;
}
.reviews-title-container button {
  margin-bottom: 5px;
}
.tresio-btn-small {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 0.9em;
}
.yelp-google-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.yelp-manual-connect {
  cursor: pointer;
  line-height: 18px;
  border: none;
  background: #e4e4e4 !important;
  color: black !important;
  border-radius: 100%;
  left: 50%;
  font-weight: 400;
}
.yelp-tutorial-popup {
  position: absolute;
  background: #fff;
  z-index: 5;
  text-align: center;
  box-shadow: 0 3px 40px -20px #000;
  padding: 30px 50px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
}
.yelp-tutorial-popup input {
  border: 1px solid #ced4da !important;
}
.yelp-tutorial-popup h3 {
  margin-bottom: 30px;
}
.yelp-tutorial-popup p {
  text-align: left;
  margin-bottom: 10px;
}
.yelp-tutorial-popup label {
  display: block;
  text-align: left;
  font-size: 12px;
  margin-bottom: 10px;
  margin-top: 20px;
}
.yelp-tutorial-popup .popup-button {
  cursor: pointer;
  margin: 0 5px;
  width: 90px;
  background: #9964f2;
  padding: 5px;
  text-align: center;
}
.default-message-loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 210px;
}
.tag-input .wrapper {
  background: white;
  width: 100%;
  min-height: 36px;
  position: relative;
  overflow: hidden;
  padding: 4px;
  padding-bottom: 0;
  display: flex;
  flex-flow: wrap;
}
.tag-input .tag {
  height: 36px;
  border-radius: 2px;
  width: fit-content;
  padding: 0 12px;
  display: flex;
  background: #9964f2;
  color: white;
  cursor: default;
  margin-right: 4px;
  margin-bottom: 4px;
}
.tag-input .tag .tag-label {
  flex: 1;
  line-height: 34px;
  font-size: 12px;
  margin-right: 16px;
}
.tag-input .tag .tag-delete {
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  line-height: 34px;
  margin-right: 0;
  opacity: 0.3;
  transition: opacity 0.2s;
}
.tag-input .tag .tag-delete:hover {
  opacity: 1;
}
.tag-input input {
  color: #495057;
  border: none;
  background: none;
  width: 300px;
  height: 36px;
  line-height: 34px;
  text-indent: 4px;
  margin-top: 0;
  margin-bottom: 4px;
}
.tag-input input.invalid {
  color: #f37676;
}
.tag-input input:focus {
  border: none;
  outline: none;
}
.select-field {
  position: relative;
  width: fit-content;
}
.select-field .select-field-button {
  padding: 0 16px;
  height: 36px;
  background: #9964f2;
  border-radius: 4px;
  line-height: 33px;
  color: white;
  cursor: pointer;
}
.select-field .select-field-options {
  border-radius: 4px;
  position: absolute;
  background: white;
  top: 40px;
  left: 0;
  z-index: 100;
  right: 0;
  padding: 8px 0;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  transform: scaleY(0) scaleX(0);
  transform-origin: top left;
  transition: all 0.2s;
}
.select-field .select-field-options .select-field-option {
  cursor: pointer;
  height: 36px;
  line-height: 33px;
  padding-left: 12px;
  border-bottom: 0px solid rgba(0, 0, 0, 0.02);
}
.select-field .select-field-options .select-field-option:first-child {
  border-top: 0px solid rgba(0, 0, 0, 0.02);
}
.select-field .select-field-options .select-field-option:hover {
  background: #9964f233;
}
.select-field.active .select-field-options {
  transform: scaleY(1) scaleX(1);
}
@media print {
  @page {
    size: A4;
    margin: 5mm 0mm;
  }
  html,
  body {
    width: 210mm;
    height: 297mm;
    width: 370mm;
    margin-left: -10mm;
  }
  * {
    -moz-print-color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
  }
  body {
    zoom: 95%;
    float: none !important;
    position: static !important;
    color: black !important;
    font-size: 16px;
    overflow: visible;
  }
  span,
  tspan,
  text {
    color: black !important;
  }
  h1 {
    font-size: 1.75em;
    padding: 1em;
  }
  table tr td {
    padding: 0.5em !important;
  }
  table tr th {
    padding: 0.75em !important;
  }
  .table th {
    font-weight: normal;
    border-bottom: 5px solid #000;
    border-color: #000;
  }
  .row {
    display: block !important;
  }
  header {
    position: relative;
    left: 30mm;
    margin-bottom: 15mm;
  }
  header .header-logo {
    width: 200px !important;
  }
  header .col-md-6 {
    width: 100% !important;
    float: none !important;
  }
  header nav {
    display: none;
  }
  .navbar .header-branding {
    margin: 0 !important;
    border: none !important;
  }
  .print-datepicker {
    display: none !important;
  }
  .recharts-responsive-container {
    width: 100%;
    height: 230px;
  }
  .print-page {
    height: 100vh;
    display: block;
    float: none !important;
    margin: 0;
    border: initial;
    border-radius: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    overflow: visible;
    box-sizing: content-box !important;
  }
  .print-page h1 {
    color: #000;
  }
  .page-break {
    break-before: always;
    page-break-before: always;
  }
  .recharts-cartesian-axis-tick text {
    fill: black !important;
  }
  .col-xl-12 {
    float: left !important;
    width: 100%;
  }
  .col-md-6 {
    width: 50% !important;
    float: left !important;
  }
  .title span {
    display: block;
    font-size: 70%;
  }
}
.platform-row {
  display: flex;
  margin-bottom: 8px;
}
.platform-row .platform-name-wrapper {
  width: 96px;
  display: flex;
}
.platform-row .platform-name-wrapper .svg-inline--fa {
  width: 20px;
  height: 20px;
  margin-top: 9px;
}
.platform-row input {
  flex: 1;
  margin-right: 16px;
}
.platform-row .platform-controls {
  display: flex;
}
.platform-row .toggle-comp {
  margin-top: 4px;
  margin-left: 16px;
  margin-right: 16px;
}
.platform-row .social-icon {
  filter: grayscale(1);
  opacity: 0.4;
}
.platform-row .social-icon.enabled {
  filter: grayscale(0);
  opacity: 1;
}
.platform-row img {
  width: 24px;
  height: 24px;
  float: right;
  margin-left: 6px;
  margin-top: 7px;
}
.input-group {
  margin-bottom: 10px;
}
.input-group .form-control {
  border-radius: 3px !important;
  margin-right: 8px;
}
.input-group .input-group-append {
  border-radius: 3px !important;
}
.input-group-prepend {
  background: none;
}
.input-group-text {
  background: none;
  padding-top: 8px;
  padding-bottom: 8px;
  min-width: 140px;
  border: none;
}
.form-control {
  border: none;
  box-shadow: none;
}
textarea {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.progress-bar {
  background-image: linear-gradient(-137deg, #a776f6 0%, #7332e4 100%);
}
.Select {
  width: 300px;
  margin: auto;
}
.Select-placeholder {
  padding-left: 14px;
}
.Select-control {
  border-radius: 40px !important;
  box-shadow: 0 1px 17px -4px rgba(0, 0, 0, 0.2);
  border: none;
}
.Select-control input {
  padding-left: 4px;
  padding-right: 4px;
}
.react-modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.08);
  background: #fff;
  border-radius: 4px;
  outline: none;
  padding: 30px;
  max-width: 700px;
  margin: auto;
  max-height: 600px;
  overflow: scroll;
}
.react-modal .react-modal-close {
  background: white;
  color: black;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 2;
}
.react-modal-overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(232, 232, 232, 0.74902);
  z-index: 996;
}
.lead-modal {
  max-width: 520px;
}
.lead-modal .lead-title {
  font-family: "sofia-pro";
  text-align: center;
  margin-top: -8px;
  font-size: 22px;
  color: #515151;
}
.lead-modal .lead-field {
  padding: 10px 0;
  color: #515151;
}
.lead-modal .lead-field span {
  display: block;
  text-transform: uppercase;
  font-size: 11px;
  color: #cbcbcb;
  margin-bottom: 1px;
}
.lead-modal .lead-field + .lead-field {
  border-top: 1px solid #f5f5f5;
}
ul.pagination {
  margin: auto;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #2a2a2a;
  border-color: #2a2a2a;
}
.pagination > li > a,
.pagination > li > span {
  color: #2a2a2a;
  border: none !important;
  text-transform: uppercase;
  font-size: 13px;
  outline: 0 !important;
}
.page-item.active .page-link {
  background-color: #2a2a2a;
  border-color: #2a2a2a;
}
.recharts-tooltip-cursor {
  fill: #f6f6f6;
}
.recharts-responsive-container {
  overflow: hidden;
}
.recharts-bar-rectangle {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
}
.recharts-bar-rectangle path {
  fill: #dce9fe !important;
}
.recharts-bar-rectangle:hover path {
  fill: #abcafc !important;
}
.yearly-graph .recharts-bar-rectangle:nth-child(13) path {
  fill: #619bf9 !important;
}
.recharts-cartesian-axis-line {
  stroke: #fff;
}
.recharts-cartesian-axis-tick text {
  fill: #bcbcbc;
}
.recharts-legend-item-text {
  text-transform: capitalize;
}
.device-graph {
  width: 230px !important;
  display: block;
  margin: auto;
  top: -30px;
}
.device-graph .recharts-pie-sector path {
  fill: #dce9fe !important;
}
.device-graph .recharts-pie-sector:nth-child(1) path {
  fill: #619bf9 !important;
}
.device-graph .recharts-pie-sector:nth-child(2) path {
  fill: #abcafc !important;
}
.recharts-default-tooltip {
  border: none !important;
  box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  text-transform: capitalize;
}
.recharts-default-tooltip .recharts-tooltip-label {
  font-weight: 500;
}
.recharts-default-tooltip .recharts-tooltip-label,
.recharts-default-tooltip .recharts-tooltip-item {
  color: #686868 !important;
}
.recharts-legend-wrapper {
  width: 100% !important;
  bottom: 0px !important;
  left: 0 !important;
}
.route-home .app-container {
  padding: 0;
}
.route-home .home-container .row {
  padding-top: 60px;
  padding-bottom: 60px;
  padding-left: 20px;
  padding-right: 20px;
}
.route-home .home-container .btn {
  font-size: 18px;
}
.route-home .home-container .btn:hover {
  box-shadow: 0px 6px 40px rgba(0, 0, 0, 0.25);
}
.route-home h1,
.route-home h2,
.route-home h3,
.route-home h4 {
  font-weight: 300;
}
.route-home h2 {
  font-size: 34px;
  line-height: 1.3;
  margin-bottom: 20px;
}
.route-home p {
  font-size: 22px;
  font-weight: 200;
  margin-bottom: 20px;
}
.route-home .is-white {
  background: #fff;
}
.route-home .is-grey {
  background: #f5f5f5;
}
.route-home .btn {
  margin-top: 20px;
}
.route-home .f-circle {
  width: 60px;
  height: 60px;
  right: 20px;
  top: 100px;
  background-image: linear-gradient(-223deg, #a776f6 0%, #7332e4 100%);
  border-radius: 100%;
  display: block;
  position: absolute;
  z-index: 999;
}
@media only screen and (max-width: 768px) {
  .route-home .f-circle {
    display: none;
  }
}
.route-home .f-circle.is-blurred {
  background-image: linear-gradient(-223deg, #fff 0%, #7332e4 100%);
  filter: blur(8px);
}
.route-home .f-circle.is-left {
  left: 10%;
  top: -300px;
}
.route-home .f-circle.is-big {
  width: 180px;
  height: 180px;
}
.route-home .f-circle.is-right {
  right: 10%;
}
.route-home .social-icons {
  max-width: 308px;
  margin-top: -10px;
}
.route-home .input-group {
  max-width: 360px;
  margin: auto;
  margin-top: 40px;
}
.route-home .input-group .alert {
  display: block;
}
.route-home .input-group .form-control {
  background: #efefef;
  box-shadow: none;
  height: 40px;
}
.route-home .input-group .form-control + .form-control {
  margin-left: 3px;
}
.route-home .input-group .btn {
  margin-top: 0;
}
.route-home .input-group .btn-disabled {
  background: #d4b5ff !important;
}
.route-home .mc-successful {
  text-align: center;
}
.route-home .mc-successful p {
  font-size: 20px;
  display: inline-block;
  padding: 6px 20px;
  background: #f3f3f3;
  border-radius: 5px;
}
.update-margin {
  margin: 0px 25px 0 25px !important;
}
.trash-margin {
  margin: 0px 11px 0 11px !important;
}
.home-hero {
  background: #fff;
  overflow: hidden;
  position: relative;
}
.home-hero:before {
  position: absolute;
  content: " ";
  background-image: linear-gradient(-223deg, #a776f6 0%, #7332e4 100%);
  width: 450vw;
  height: 450vw;
  bottom: 0;
  display: block;
  border-radius: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}
.home-hero .row {
  position: relative;
  padding-top: 176px !important;
  padding-bottom: 80px !important;
}
@media only screen and (max-width: 768px) {
  .home-hero .row {
    padding-top: 126px !important;
  }
}
.home-hero .row > [class*="col"] {
  position: relative;
  z-index: 2;
}
@media only screen and (max-width: 768px) {
  .home-hero .row > [class*="col"] {
    z-index: 99;
  }
}
.home-hero_image {
  position: relative;
}
.home-hero_image img {
  margin-bottom: -80px;
}
@media only screen and (max-width: 768px) {
  .home-hero_image img {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: 50px;
  }
}
.home-hero h1 {
  color: #fff;
  font-size: 44px;
  font-weight: 300;
  margin-top: 100px;
}
@media only screen and (max-width: 768px) {
  .home-hero h1 {
    margin-top: 0;
    text-align: center;
    font-size: 30px;
    margin-bottom: 60px;
  }
}
.home-hero .btn {
  background-color: #fff !important;
  color: #9964f2 !important;
}
.home-hero .c {
  background-image: linear-gradient(-223deg, #a776f6 0%, #7332e4 100%);
  position: absolute;
  border-radius: 100%;
  display: block;
  animation-direction: alternate !important;
  animation-iteration-count: infinite !important;
}
.home-hero .c.c-1 {
  width: 80px;
  height: 80px;
  right: 20px;
  top: 100px;
  animation: bounce 2.3s cubic-bezier(0.5, 0.05, 0.05, 0.5);
  z-index: 9;
}
.home-hero .c.c-2 {
  width: 160px;
  height: 160px;
  bottom: 123px;
  left: 30%;
  animation: bounce 3s cubic-bezier(0.5, 0.05, 0.05, 0.5) 200ms;
}
.home-hero .c.c-3 {
  width: 480px;
  height: 480px;
  left: -150px;
  top: -200px;
  animation: bounce 2.6s cubic-bezier(0.5, 0.05, 0.05, 0.5) 700ms;
}
.home-steps {
  text-align: center;
}
.home-steps h3 {
  margin-bottom: 20px;
}
.home-steps p {
  font-size: 18px;
}
@media only screen and (max-width: 768px) {
  .home-steps .col-md-4 {
    padding-left: 40px;
    padding-right: 40px;
    margin-bottom: 30px;
  }
}
.home-steps .col-md-4 + .col-md-4 {
  border-left: 2px solid rgba(255, 255, 255, 0.09);
}
@media only screen and (max-width: 768px) {
  .home-steps .col-md-4 + .col-md-4 {
    border: none;
  }
}
.home-steps .icon {
  width: 34px;
  height: 34px;
  background-image: linear-gradient(-223deg, #a776f6 0%, #7332e4 100%);
  border-radius: 100%;
  position: relative;
  margin-bottom: 20px;
  display: inline-block;
  box-shadow: 0px 3px 12px -2px rgba(0, 0, 0, 0.45);
}
.home-steps .icon img {
  width: 17px;
  display: block;
  margin: auto;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
}
.home-doc {
  position: relative;
  overflow: hidden;
  padding-top: 120px;
  margin-top: -120px;
  text-align: left;
}
@media only screen and (max-width: 768px) {
  .home-doc {
    background: #fff;
    margin-top: 0;
    padding-top: 60px;
    text-align: center;
  }
  .home-doc:before {
    display: none;
  }
}
.home-doc:before {
  content: " ";
  position: absolute;
  width: 100%;
  height: 800px;
  background: #fff;
  top: 170px;
  right: 0;
  -webkit-transform: skew(0, -10deg);
  -moz-transform: skew(0, -10deg);
  -ms-transform: skew(0, -10deg);
  -o-transform: skew(0, -10deg);
  transform: skew(0, -10deg);
}
.home-doc .row {
  position: relative;
  z-index: 2;
  max-width: 1000px;
  padding-top: 0 !important;
}
.home-doc h2 {
  margin-bottom: 20px;
}
.home-doc h2,
.home-doc h3,
.home-doc p {
  color: #434343 !important;
}
.home-doc p {
  margin-bottom: 0;
}
@media only screen and (max-width: 768px) {
  .home-doc img {
    max-width: 200px;
    display: block;
    margin: auto;
  }
}
.home-features {
  background: #f5f5f5;
}
.has-circle-bg {
  position: relative;
  overflow: hidden;
}
.has-circle-bg:before {
  position: absolute;
  content: " ";
  background-image: linear-gradient(-223deg, #462d71 0%, #32224d 100%);
  width: 450vw;
  height: 450vw;
  top: 0;
  display: block;
  border-radius: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}
.has-circle-bg .row {
  position: relative;
  z-index: 2;
}
.has-circle-bg h2,
.has-circle-bg h3,
.has-circle-bg h4,
.has-circle-bg p {
  color: #fff;
}
@media only screen and (max-width: 768px) {
  .home-tabs-list {
    margin-bottom: 40px;
  }
}
.home-tabs-list ul {
  padding: 0;
}
.home-tabs-list li {
  box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 3px;
  padding: 16px;
  overflow: auto;
  margin-bottom: 20px;
  display: block;
  position: relative;
  background: #fff !important;
  text-align: left;
  font-weight: 500;
  font-size: 22px;
  cursor: pointer;
  transition: all 300ms ease-in-out;
}
.home-tabs-list li.active {
  border-left: 4px solid #9964f2;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  box-shadow: 0 15px 25px 0px rgba(0, 0, 0, 0.1);
}
.home-tabs-list li span {
  display: inline-block;
  background: #9964f2;
  color: #fff;
  font-size: 15px;
  padding: 5px 16px;
  border-radius: 5px;
  float: right;
}
@media only screen and (max-width: 768px) {
  .home-tabs-list li span {
    float: none;
    margin-top: 10px;
  }
}
.home-tabs-image img {
  max-width: 65%;
  margin: auto;
  display: block;
  box-shadow: 0px 10px 50px -22px #9e38e6;
}
@media only screen and (max-width: 768px) {
  .home-tabs-image img {
    max-width: 100%;
  }
}
.pricing-table {
  overflow: hidden !important;
  border-radius: 10px;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 2px 16px -11px #000;
  margin-top: 40px;
}
.pricing-table .top {
  background: #fff;
  padding: 20px;
  border-bottom: 2px solid #eaeaea;
}
.pricing-table .bottom {
  background: #fff;
  padding: 30px 20px;
}
.pricing-table .bottom ul {
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: left;
  max-width: 230px;
  margin-left: auto;
  margin-right: auto;
}
.pricing-table .bottom ul li {
  margin-bottom: 10px;
}
.pricing-table .bottom svg {
  margin-right: 10px;
}
.pricing-table .bottom svg * {
  fill: #2bd897;
}
.pricing-table .price {
  font-size: 50px;
  margin-bottom: 0;
  font-weight: bold;
  color: #9964f2;
  line-height: 1;
}
.pricing-table .price span {
  display: inline-block;
  font-size: 28px;
  font-weight: 100;
}
@keyframes bounce {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(0, 120px, 0);
  }
}
.input-gray {
  background: #f8f8f8;
}
@media only screen and (max-width: 768px) {
  .mobile-responsive-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .mobile-responsive-flex h1 {
    margin-bottom: 30px;
  }
  .mobile-responsive-flex .btn {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 500px) {
  .checkout {
    width: 400px;
  }
  .mobile-responsive-nav {
    display: flex;
  }
}
@media only screen and (max-width: 410px) {
  .checkout {
    width: 350px;
  }
  .user-info-container > .info-group {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .registration_input {
    width: 100%;
  }
  .input-location-style {
    width: 100%;
  }
}
@media only screen and (max-width: 360px) {
  .checkout {
    width: 320px;
  }
  .user-info-container > .info-group {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .registration_input {
    width: 100%;
  }
}
.clients .profile-container .title {
  text-align: center;
  font-size: 22px;
}
.clients .profile-container .title span.category {
  font-size: 16px;
  display: block;
  color: #adafb1;
  margin-top: 6px;
  text-transform: uppercase;
}
.clients .profile-container .file-preview {
  text-align: center;
  margin-bottom: 20px;
  position: relative;
}
.clients .profile-container .file-preview > .btn {
  position: absolute;
  right: 0;
  bottom: -10px;
  left: 0;
  color: #ffffff;
  margin: auto;
  background: #2a2a2a;
  height: 32px;
  width: 32px;
  text-align: center;
  line-height: 32px !important;
  padding: 0;
}
.clients .profile-container .client-profile-image {
  display: block;
  margin: auto;
  margin-bottom: 20px;
  max-width: 120px;
  border-radius: 100%;
}
.clients .profile-container input {
  width: 180px;
  margin: auto;
  display: block;
  margin-bottom: 10px;
}
.clients .note-item {
  padding: 20px 0;
  position: relative;
}
.clients .note-item:hover button {
  opacity: 1;
  visibility: visible;
}
.clients .note-item.my-note .note-content {
  border-color: #5d5d5d;
}
.clients .note-item + .note-item {
  border-top: 1px solid #efefef;
}
.clients .note-item .note-meta {
  margin-bottom: 20px;
}
.clients .note-item .note-meta img {
  height: 24px;
  border-radius: 100%;
  margin-right: 10px;
}
.clients .note-item .note-meta .date {
  float: right;
  font-size: 12px;
  color: #b9b9b9;
}
.clients .note-item .note-content {
  padding-left: 10px;
  border-left: 3px solid #ececec;
}
.clients .note-item .note-content .note-content-date {
  padding-bottom: 1em;
  font-style: italic;
}
.clients .note-item button {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 20px;
  right: 0;
  z-index: 9;
}
.clients .tabs {
  margin: 0;
  padding: 0px;
  box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 3px;
  margin-bottom: 30px;
  background: #fff;
}
.clients .tabs li {
  display: inline-block;
}
.clients .tabs a {
  display: block;
  padding: 20px;
  text-transform: uppercase;
  color: #939598;
}
.clients .tabs a.active {
  color: #2a2a2a;
  box-shadow: inset 0px -2px;
}
.clients .sites-list {
  padding-bottom: 0;
}
.clients .sites-list .box-title {
  margin-bottom: 0;
}
.clients .sites-list .site-item {
  position: relative;
  padding: 20px 16px 20px 54px;
}
.clients .sites-list .site-item:before {
  content: " ";
  position: absolute;
  width: 16px;
  height: 16px;
  background: #e6e6e6;
  border-radius: 100%;
  top: 27px;
  left: 20px;
}
.clients .sites-list .site-item.health-healthy:before {
  content: " ";
  background: #8be278;
}
.clients .sites-list .site-item.health-warning:before {
  content: " ";
  background: #e2dc78;
}
.clients .sites-list .site-item.health-danger:before {
  content: " ";
  background: #e27878;
}
.clients .sites-list .site-url {
  color: #c1c1c1;
  margin-bottom: 0;
}
.clients .client-todos-list {
  border-bottom: 1px solid #efefef;
}
.clients .client-todos-list:last-child {
  border: none;
}
.clients .client-todos-item {
  line-height: 3;
  font-size: 14px;
  margin-left: 1em;
  color: #939598;
}
.clients .sb-ticket {
  padding: 20px 20px;
  overflow: auto;
}
.clients .sb-ticket .ticket-title {
  font-size: 16px;
}
.clients .sb-ticket .btn {
  float: right;
}
.clients .sb-ticket .ticket-requester {
  display: block;
  font-size: 87%;
  color: #c7c7c7;
  margin-top: 4px;
}
.clients .sb-labels .label {
  border: none;
  font-size: 12px;
  opacity: 0.8;
}
.clients .contact-item {
  padding: 16px 0;
  position: relative;
}
.clients .contact-item + .contact-item {
  border-top: 1px solid #ececec;
}
.clients .contact-item .initials {
  width: 35px;
  height: 35px;
  line-height: 34px;
  text-align: center;
  border-radius: 100%;
  margin-right: 16px;
  position: absolute;
  left: 16px;
  color: #eeb85d;
  border: 1px solid #eeb85d;
}
.clients .contact-item input {
  background: none;
  border: none;
  margin-bottom: 2px;
  padding: 2px;
  width: 100%;
  outline: 0;
}
.clients .contact-item .contact-name {
  color: #000;
}
.clients .contact-item .contact-title,
.clients .contact-item .contact-phone,
.clients .contact-item .contact-email {
  font-size: 14px;
}
.clients .contact-item .contact-delete {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #b9b9b9;
}
.clients .contact-item .input-wrap {
  position: relative;
}
.clients .contact-item .input-wrap img {
  position: absolute;
  left: 0;
  top: 4px;
  width: 13px;
  filter: grayscale(100%);
}
.clients .contact-item .input-wrap input {
  padding-left: 24px;
}
.slickContainer {
  margin: 0 auto;
  width: 100%;
  color: #333;
}
.slickContainer .slick-slide {
  opacity: 0.5;
  transition: all 250ms ease-in-out;
}
.slickContainer .slick-slide.slick-active {
  opacity: 1;
}
.slickContainer .slick-slide:not(.slick-active) .dv-star-rating-star {
  color: #dedede !important;
}
.slickContainer .slick-slide .review-widget-container {
  font-family: sofia-pro, sans-serif;
  min-height: 185px;
  padding: 20px;
  border-radius: 5px;
  font-weight: 200;
  margin-left: 10px;
  margin-right: 10px;
  background: #ffffff;
  width: 346px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
.slickContainer .slick-slide .position-effect {
  transform: translateY(-6px);
}
.slickContainer .slick-slide .slick-with-count-container {
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.slickContainer .slick-slide .slick-with-count-container .widget-counter {
  display: none !important;
  position: absolute;
  width: 30px;
  height: 30px;
  background: #9964f2;
  color: #fff;
  top: 0;
  left: 0;
  border-radius: 100%;
  text-align: center;
  line-height: 30px;
  z-index: 9;
}
.slickContainer .slick-track {
  display: flex;
  align-items: center;
  display: flex;
}
.slickContainer .slick-arrow {
  border: 1px solid red;
}
.slickContainer-adjusted {
  margin: 0 auto;
  width: 100%;
  color: #333;
}
.slickContainer-adjusted .slick-slide .review-widget-container {
  margin-left: 10px;
  margin-right: 10px;
  background: #fff;
  box-shadow: none !important;
}
.slickContainer-adjusted .slick-slide .position-effect {
  background: #9964f2;
  color: white;
}
.slickContainer-adjusted .slick-slide .slick-with-count-container {
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.slickContainer-adjusted .slick-slide .slick-with-count-container {
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.slickContainer-adjusted .slick-track {
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
}
.slickContainer-adjusted .slick-arrow {
  border: 1px solid red;
}
.preview-widget-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.preview-widget-container h1 {
  text-align: center;
}
.slider-width {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  padding: 60px 0;
  margin: auto;
  border-radius: 5px;
}
.back-to-location {
  display: inline-block;
  margin-bottom: 30px;
  font-size: 1.3em;
  font-weight: 500;
  color: #9964f2;
}
.progress-item {
  position: relative;
  overflow: auto;
}
.progress-item + .progress-item {
  margin-top: 11px;
}
.progress-item .progress-label {
  color: #424242;
  font-weight: 500;
  font-size: 12px;
  width: 70px;
  float: left;
  text-align: right;
  padding-right: 8px;
  position: relative;
}
.progress-item .progress-label svg {
  height: 10px;
  position: relative;
}
.progress-item .progress-label span {
  position: absolute;
  top: 55%;
  right: 18px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 2;
  color: #9964f2;
  font-weight: 500;
  line-height: 1;
  font-size: 10px;
  text-align: center;
}
.progress-item .progress-label img {
  height: 24px;
}
.progress-item .progress {
  float: left;
  height: 15px;
  width: calc(100% - 70px);
  border-radius: 30px;
  margin-top: 3px;
}
.progress-item .progress .progress-bar {
  border-radius: 30px;
}
.customer-table table {
  box-shadow: 0px 3px 9px -3px #eaeaea;
  border-radius: 4px;
  background: #fff;
}
.customer-table .filterable div {
  position: relative;
}
.customer-table .filterable div span {
  position: absolute;
  top: 50%;
  left: 10px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 2;
  color: #fff;
  font-weight: 500;
  line-height: 1;
  font-size: 12px;
}
.customer-table th {
  color: #afafaf;
  font-weight: 500;
}
.customer-table td {
  max-width: 460px;
  white-space: nowrap;
}
.customer-table td:last-of-type {
  white-space: normal;
}
.customer-table svg {
  margin-left: 5px;
}
.customer-table img {
  max-width: 26px;
}
.customer-table .header-row {
  margin-bottom: 16px;
}
.customer-table .filter-container {
  padding: 0;
}
.customer-table .filter-container .clear-filter {
  position: absolute;
  top: 3px;
  right: 10px;
}
.customer-table .exact-filters {
  margin-left: -15px;
  margin-right: -15px;
}
.customer-table .filter-item {
  padding: 3px 14px;
  background: #619cf8;
  color: #fff;
  border-radius: 24px;
}
.customer-table .filter-item .filter-item-remove,
.customer-table .filter-item .filter-item-title {
  margin-right: 5px;
}
.customer-table .filter-item .filter-item-remove svg,
.customer-table .filter-item .filter-item-title svg {
  margin: 0;
}
.customer-table .status-filtered {
  color: #fff;
  background: #ff3131;
  display: inline-block;
  padding: 5px 13px;
  border-radius: 30px;
  font-size: 11px;
  line-height: 1;
  letter-spacing: 0.6px;
  text-transform: uppercase;
}
.customer-table .record-count {
  text-align: right !important;
}
.customer-table .rating {
  color: #7332e4;
  background: #dcc0ff;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 30px;
  font-size: 11px;
  line-height: 1;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  font-weight: 500;
}
.customer-table .rating svg {
  margin: 0;
  height: 8px;
  position: relative;
  top: -1px;
}
.customer-table .rating svg * {
  fill: #7332e4;
}
.customer-table .pagination a {
  display: block;
  padding: 4px 8px;
}
.customer-table .pagination .active a {
  background: #e8e8e8;
}
.overall-rating {
  padding: 41px 30px;
  box-shadow: 0px 3px 9px -3px #eaeaea;
  border-radius: 4px;
  text-align: center;
  background: #9964f2;
  color: #fff;
}
.overall-rating .rating {
  font-size: 80px;
  display: block;
  line-height: 0.7;
  margin-bottom: 20px;
}
.overall-rating .ratings-total {
  font-weight: 500;
  color: #e3f0fe;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 11px;
}
.request-review-wrap {
  padding: 30px;
  background: #fff;
  box-shadow: 0px 3px 9px -3px #eaeaea;
  border-radius: 4px;
}
.request-review-wrap input,
.request-review-wrap textarea {
  border: 1px solid #efefef;
}
.listing-item {
  padding: 20px 16px;
  padding-left: 60px;
  box-shadow: 0px 3px 9px -3px #eaeaea;
  border-radius: 4px;
  background: #fff;
  position: relative;
}
.listing-item .listing-platform {
  width: 28px;
  padding: 5px;
  background: #fff;
  display: inline-block;
  border-radius: 3px;
  -webkit-box-shadow: 0 2px 9px -2px #c7c7c7;
  box-shadow: 0 2px 9px -2px #c7c7c7;
  margin-right: 14px;
  position: absolute;
  left: 16px;
  top: 20px;
}
.listing-item .listing-title {
  display: inline-block;
  font-size: 18px;
  margin-bottom: 0;
  position: relative;
  top: 3px;
}
.listing-item .listing-rating {
  display: block;
  margin-top: 15px;
}
.listing-item .listing-rating svg {
  height: 20px;
  width: 20px;
  padding: 5px;
  background: #f15b4e;
  display: inline-block;
  border-radius: 3px;
  margin-right: 5px;
}
.listing-item .listing-rating svg * {
  fill: #fff;
}
.listing-item .listing-rating svg.fa-star-half {
  background: -moz-linear-gradient(
    right,
    #f15b4e 0%,
    #f15b4e 50%,
    #d0d0d0 50%,
    #d0d0d0 100%
  );
  background: -webkit-linear-gradient(
    right,
    #f15b4e 0%,
    #f15b4e 50%,
    #d0d0d0 50%,
    #d0d0d0 100%
  );
  background: linear-gradient(
    to right,
    #f15b4e 0%,
    #f15b4e 50%,
    #d0d0d0 50%,
    #d0d0d0 100%
  );
}
.listing-item .listing-rating svg.empty {
  background: #d0d0d0;
}
.listing-item .listing-claimed {
  font-weight: 500;
  display: block;
  margin-top: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 11px;
}
.listing-item .listing-claimed svg {
  width: 14px;
  position: relative;
  top: 2px;
}
.listing-item .listing-claimed svg * {
  fill: #619bf9;
}
.welcome-empty-container img {
  width: 50%;
  margin-bottom: 60px;
}
.welcome-empty-container h3 {
  margin-bottom: 10px;
  line-height: 1;
}
.welcome-empty-container p {
  margin-bottom: 20px;
}
.test-button {
  font-size: 1.2em;
  border: none;
}
.test-button :hover {
  cursor: pointer;
}
.valid {
  display: flex;
}
.valid p {
  margin: 3px;
}
.invalid-container {
  box-shadow: 0 0 8px #ccc;
  position: relative;
  border-radius: 5px;
  margin: 10px 0 10px 0;
  display: flex;
  width: 375px;
  padding: 5px;
  margin: 15px;
}
.invalid-container p {
  margin: 3px;
}
.invalid-container .customer-info {
  display: flex;
  flex-direction: column;
  width: 70%;
  justify-content: center;
  padding: 5px;
}
.invalid-container .customer-info .valid {
  color: #424242;
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: -0.4px;
}
.invalid-container .customer-info .highlight-invalid {
  color: red;
  font-size: 1.1em;
  font-weight: bold;
}
.invalid-container .customer-info .highlight-invalid:after {
  content: "***";
  width: 50px;
  height: 50px;
  background: green;
}
.invalid-container .customer-info .form-group {
  margin: 2px;
}
.invalid-container .customer-info .inline-highlight-container {
  display: flex;
  align-items: center;
}
.invalid-container .customer-info .inline-highlight-container svg {
  margin: 5px;
}
.invalid-container .customer-info .inline-highlight-container .fa-check {
  color: #4ad841;
}
.invalid-container .customer-info .inline-highlight-container .fa-times {
  color: red;
}
.invalid-container .invalid-actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px;
}
.invalid-container .invalid-actions button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  font-size: 1.1em;
  margin: 5px;
  color: #9964f2;
  background: #ffffff;
  font-weight: 500;
  box-shadow: 0 0 8px #ccc;
}
.invalid-container .invalid-actions button:hover {
  background: #9964f2;
  color: #ffffff;
}
.invalid-highlight-p {
  color: red;
  font-size: 0.85em;
  font-weight: bold;
}
.csv-data-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.csv-data-container .entries-count-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  width: 50%;
}
.csv-data-container .entries-count-container .entries {
  margin: 5px 0 5px 0;
  text-align: center;
  width: 100%;
  font-size: 1.4em;
  background: #9964f2;
  border-radius: 5px;
  padding: 10px;
  color: white;
}
.csv-data-container .entries-count-container .entries button {
  background: #82d682;
  color: white;
  border-radius: 3px;
  font-size: 0.9em;
  font-weight: 500;
}
.csv-data-container .entries-count-container .entries button:hover {
  background: #88e188;
}
.csv-data-container .entries-count-container .entries p {
  margin: 2px;
}
.csv-data-container .entries-count-container .failed-bg {
  background: #ff3232;
}
.inputs-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.review-request-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.custom-message-container {
  margin: 30px 0;
}
.custom-message-container .custom-message-header {
  display: flex;
  justify-content: space-between;
}
.custom-message-container .custom-message-header .select-field {
  margin-bottom: 8px;
}
.custom-message-container .input-mod {
  background: #ffffff;
  width: 100%;
  border-radius: 10px;
  height: 115px;
}
.custom-message-container p {
  font-family: "Sofia-pro";
  font-size: 14px;
  color: #878787;
  text-transform: uppercase;
  margin-left: 2px;
}
.input-mod {
  background: #ffffff;
  width: 100%;
  border-radius: 20px;
  height: 115px;
}
.sample-csv p {
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  background: #9964f2;
  margin-bottom: 0;
  color: #fff;
  padding: 10px 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.sample-csv tbody {
  border-radius: 7px !important;
  box-shadow: 0 9px 12px -3px rgba(0, 0, 0, 0.2);
}
.sample-csv tr {
  border-bottom: 2px solid #f1f1f1 !important;
}
.sample-csv tr:last-child {
  border-bottom: none !important;
}
.sample-csv .column-name {
  font-weight: 500;
  background: #ffffff;
  color: #6f6f6f;
}
.request-review-info-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 30px;
}
.request-review-info-container .csv-input-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.request-review-info-container .csv-input-container h4 {
  margin: 0 0 20px 0;
}
.request-review-info-container .request-review-info {
  padding: 20px;
  margin: 0% 2% 0 2%;
}
.request-review-info-container .request-review-info p {
  font-size: 1.2em;
}
.request-review-info-container .file-input {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 70%;
  height: 415px;
}
.request-review-info-container .file-input h2 {
  margin: 10px 0 10px 0;
}
.input-bar :hover {
  cursor: pointer;
}
.csv-format-error-container {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}
.success-count {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  font-weight: bold;
  margin-bottom: 15px;
}
.success-count span {
  padding: 5px;
}
.success-count svg {
  margin-right: 10px;
}
.add-review-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #fff;
  position: relative;
}
.add-review-container .addition-button {
  position: absolute;
  left: -50px;
  z-index: 5;
  background: transparent;
}
.add-review-container .social-buttons {
  width: 96px;
  float: right;
  display: flex;
}
.add-review-container .social-buttons .social-button {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  opacity: 0.3;
  filter: grayscale(1);
  cursor: pointer;
}
.add-review-container .social-buttons .social-button.enabled {
  -webkit-filter: initial;
  filter: initial;
  opacity: 1;
}
.add-review-container .input-headers .social-buttons {
  margin-top: 13px;
}
.add-review-container .button-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-review-container .button-wrap button {
  width: 100px;
  margin: 5px;
}
.add-review-container .review-addition-form {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
}
.add-review-container .review-addition-form ::placeholder {
  color: #f37676;
  opacity: 1;
}
.add-review-container .review-addition-form :-ms-input-placeholder {
  color: #f37676;
}
.add-review-container .review-addition-form ::-ms-input-placeholder {
  color: #f37676;
}
.add-review-container .review-addition-form .confirm-info {
  width: 80px;
  font-size: 0.7em;
  padding: 6px;
  margin-right: 10px;
  background-color: #9964f2;
}
.add-review-container .review-addition-form button {
  align-items: center;
  width: 40px;
  text-align: center;
}
.input-type-customer {
  flex: 1;
  display: inline-flex;
  justify-content: flex-start;
  padding: 10px 15px 10px 15px;
}
.input-headers {
  width: 100%;
  display: flex;
  color: #d8d8d8;
  text-transform: uppercase;
  justify-content: space-around;
  border-bottom: 2px solid #f6f6f6;
}
.input-headers .last {
  width: 72px;
}
.input-container {
  align-items: center;
  width: 100%;
  justify-content: space-around;
  border-bottom: 2px solid #f6f6f6;
  display: flex;
}
.input-container .new-form {
  display: inline-flex;
  border: none;
  height: 50px;
  align-items: center;
  flex: 1;
}
.input-container .error-red {
  color: #f37676;
}
.input-container .form-control {
  background: transparent;
  padding: 10px 15px 10px 15px;
}
.input-highlight {
  background-color: #f6f6f6;
}
.input-highlight-no {
  background-color: transparent;
}
.csv-text-not-big {
  font-size: 18px;
  font-weight: 200;
  margin-bottom: 20px;
}
.csv-text-not-big span {
  font-weight: 500;
}
.file-div {
  padding-left: 22px;
  padding-right: 22px;
  outline: 0 !important;
  background: #9964f2;
  font-weight: 300;
  border: none;
  color: #fff;
  border-radius: 4px;
  line-height: 2;
  vertical-align: initial;
}
.file-upload {
  display: block;
}
.file-upload svg {
  display: table;
  margin: auto;
  font-size: 28px;
}
.file-upload svg * {
  fill: #e0e0e0;
}
.file-upload h4 {
  margin: 10px 0 10px 0;
}
.file-upload.active .file-select {
  border-color: #3fa46a;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}
.file-upload.active .file-select-button {
  background: #3fa46a;
  color: #ffffff;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}
.file-upload .file-select {
  display: block;
  border: 4px dotted #e0e0e0;
  color: #34495e;
  cursor: pointer;
  text-align: center;
  position: relative;
  padding: 28px;
  border-radius: 16px;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}
.file-upload .file-select:hover {
  border-color: #a8a8a8;
}
.file-upload .file-select-button {
  padding: 0 10px;
  line-height: 39px;
  font-size: 1.2em;
  font-weight: bold;
  color: #717171;
}
.file-upload .file-select-name {
  font-weight: bold;
  color: #b7b7b7;
  display: inline-block;
  padding: 0 10px;
}
.file-upload .file-select input[type="file"] {
  z-index: 100;
  cursor: pointer;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  filter: alpha(opacity=0);
}
.file-upload .file-select.file-select-disabled {
  opacity: 0.65;
}
.file-upload .file-select.file-select-disabled:hover {
  display: block;
  border: 2px solid #dce4ec;
  color: #34495e;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  margin-top: 5px;
  text-align: left;
  background: #9964f2;
  overflow: hidden;
  position: relative;
}
.file-upload .file-select.file-select-disabled:hover .file-select-button {
  background: #9964f2;
  color: #666666;
  padding: 0 10px;
  display: inline-block;
  height: 40px;
  line-height: 40px;
}
.file-upload .file-select.file-select-disabled:hover .file-select-name {
  line-height: 40px;
  display: inline-block;
  padding: 0 10px;
}
.success-notification {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 1.5em;
}
.success-notification button {
  font-size: 1em;
}
.invalid-input {
  background: transparent;
  border-bottom: 1px solid #999999;
  border-radius: 0;
  padding: 0;
}
.error-boundry-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.error-boundry-content {
  position: relative;
  bottom: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.input-container {
  position: relative;
}
.input-container .form-group {
  margin-bottom: 0;
}
.reviews-pagination-buttons-request {
  padding: 16px 0;
}
.reviews-pagination-buttons-request span {
  margin-right: 16px;
  margin-left: 16px;
  font-weight: bold;
  color: #878787;
}
.reviews-pagination-buttons-request .btn {
  padding-left: 10px;
  padding-right: 10px;
  line-height: 1.5;
}
.reviews-pagination-buttons-request .btn svg {
  margin: 0;
}
.reviews-pagination-buttons {
  padding: 16px 0;
  position: absolute;
  bottom: -75px;
}
.reviews-pagination-buttons span {
  margin-right: 16px;
  margin-left: 16px;
  font-weight: bold;
  color: #878787;
}
.reviews-pagination-buttons .btn {
  padding-left: 10px;
  padding-right: 10px;
  line-height: 1.5;
}
.reviews-pagination-buttons .btn svg {
  margin: 0;
}
.fa-buttons {
  background: transparent;
}
.fa-buttons:hover {
  cursor: pointer;
}
.button-container-csv-data {
  width: 72px;
  display: flex;
  justify-content: flex-end;
}
.button-container-csv-data .fa-buttons {
  width: 40px;
}
.button-container-csv-data svg {
  width: 12px !important;
}
.button-container-csv-data svg * {
  fill: #adadad;
}
.confirm-info {
  width: 80px;
  font-size: 0.7em;
  padding: 6px;
  margin-right: 10px;
  background-color: #9964f2;
}
.csv-container {
  margin-bottom: 40px;
  margin-top: 30px;
}
.green {
  color: #7ad07a;
  margin-right: 5px;
}
.red {
  color: #f37676;
}
.content-with-header-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.content-with-header-container .align-wrapper {
  max-width: 100%;
  width: 100%;
}
.content-with-header-container .subtitleSpaceContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.content-with-header-container
  .subtitleSpaceContainer
  .subtitleSpaceLeft
  .widget-actions {
  display: flex;
  align-items: center;
}
.content-with-header-container .widget-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.content-with-header-container .review-widget-container {
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  background: #ffffff;
  width: 100%;
  min-height: 185px;
  padding: 22px;
  padding-bottom: 46px;
  box-shadow: 0 4px 1px 0 rgba(219, 219, 219, 0.5);
  border-radius: 11px;
  position: relative;
}
.content-with-header-container .review-widget-container .review-widget-review {
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.content-with-header-container .review-widget-container .review-widget-head {
  width: 100%;
}
.content-with-header-container
  .review-widget-container
  .review-widget-head
  .review-person-name {
  float: left;
  font-weight: 500;
}
.content-with-header-container
  .review-widget-container
  .review-widget-head
  .star-container {
  float: right;
}
.content-with-header-container
  .review-widget-container
  .review-widget-functionality {
  width: 100%;
  color: gray;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 22px 22px 22px;
  cursor: pointer;
}
.content-with-header-container
  .review-widget-container
  .review-widget-functionality
  .add-widget-container {
  float: left;
  width: 70%;
  font-size: 12px;
  padding-left: 30px;
}
.content-with-header-container
  .review-widget-container
  .review-widget-functionality
  .add-widget-container
  .spinner {
  margin: 0;
  width: 16px !important;
  height: 16px !important;
}
.content-with-header-container
  .review-widget-container
  .review-widget-functionality
  .add-widget-container
  span {
  position: absolute;
  left: 22px;
}
.content-with-header-container
  .review-widget-container
  .review-widget-functionality
  .widget-buttons-container {
  display: flex;
  justify-content: space-between;
  width: 30%;
  align-items: center;
  position: relative;
  top: 8px;
}
.content-with-header-container
  .review-widget-container
  .review-widget-functionality
  .widget-buttons-container
  span {
  cursor: pointer;
}
.content-with-header-container
  .review-widget-container
  .review-widget-functionality
  #heart {
  position: relative;
  bottom: -3px;
  left: 3px;
  width: 28px;
  height: 20px;
  -webkit-animation: heartbeat 1s infinite;
  animation: heartbeat 1s infinite;
  margin: 0 auto;
  cursor: pointer;
}
.content-with-header-container
  .review-widget-container
  .review-widget-functionality
  .red {
  color: red;
}
.content-with-header-container
  .review-widget-container
  .review-widget-functionality
  .red
  #heart:before,
.content-with-header-container
  .review-widget-container
  .review-widget-functionality
  .red
  #heart:after {
  position: absolute;
  content: "";
  left: 9px;
  top: 0;
  width: 10px;
  height: 15px;
  background: red;
  -moz-border-radius: 50px 50px 0 0;
  border-radius: 50px 50px 0 0;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 0 100%;
  -moz-transform-origin: 0 100%;
  -ms-transform-origin: 0 100%;
  -o-transform-origin: 0 100%;
  transform-origin: 0 100%;
}
.content-with-header-container
  .review-widget-container
  .review-widget-functionality
  .red
  #heart:after {
  left: 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 100% 100%;
  -moz-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  -o-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}
.content-with-header-container
  .review-widget-container
  .review-widget-functionality
  .red
  .spinner {
  width: 16px;
  height: 16px;
  margin-right: 2px;
}
.content-with-header-container
  .review-widget-container
  .review-widget-functionality
  .grey {
  color: grey;
}
.content-with-header-container
  .review-widget-container
  .review-widget-functionality
  .grey
  #heart:before,
.content-with-header-container
  .review-widget-container
  .review-widget-functionality
  .grey
  #heart:after {
  position: absolute;
  content: "";
  left: 9px;
  top: 0;
  width: 10px;
  height: 15px;
  background: grey;
  -moz-border-radius: 50px 50px 0 0;
  border-radius: 50px 50px 0 0;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 0 100%;
  -moz-transform-origin: 0 100%;
  -ms-transform-origin: 0 100%;
  -o-transform-origin: 0 100%;
  transform-origin: 0 100%;
}
.content-with-header-container
  .review-widget-container
  .review-widget-functionality
  .grey
  #heart:after {
  left: 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 100% 100%;
  -moz-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  -o-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}
.content-with-header-container h4 {
  color: #bcbcbc;
}
.widget-container {
  width: 100%;
  height: 100%;
  align-items: center;
  position: relative;
}
.reusable-dropdown-container {
  min-width: 170px;
  position: relative;
  height: 30px;
  display: inline-flex;
  justify-content: space-between;
  padding: 0 13px 0 13px;
  align-items: center;
  z-index: 1;
  margin-left: 20px;
  background: #fff;
  border-radius: 52px;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
}
.reusable-dropdown-container .dv-star-rating {
  margin-top: 7px;
}
.reusable-dropdown-container .selected {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}
.reusable-dropdown-container .selected span {
  display: flex;
  justify-content: center;
  align-items: center;
}
.reusable-dropdown-container .searchbar-dropdown {
  background-color: transparent;
  text-decoration: none;
  border: none;
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 0.25rem;
  box-shadow: none;
}
.reusable-dropdown-container .dropdown-list {
  display: flex;
  width: 143px;
  justify-content: space-between;
  box-shadow: 0px 3px 9px -3px #c7c7c7;
  align-items: center;
  background: #fafafa;
  position: absolute;
  flex-direction: column;
  top: 33px;
  color: black;
  left: -1px;
  z-index: 2;
  max-height: 150px;
  overflow: scroll;
}
.reusable-dropdown-container .dropdown-list .dropdown-selection {
  width: 100%;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 5px;
  z-index: 10;
  cursor: pointer;
}
.reusable-dropdown-container .dropdown-list .dropdown-selection:after {
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: block;
}
.reusable-dropdown-container .dropdown-list .dropdown-selection div {
  z-index: -1;
}
.reusable-dropdown-container .dropdown-list .highlight {
  background: #f1f1f1;
  z-index: 99;
}
.reusable-dropdown-container .dropdown-list .highlight .dv-star-rating-star {
  z-index: 0;
}
.dropdown-container {
  min-width: 170px;
  position: relative;
  height: 30px;
  display: inline-flex;
  justify-content: space-between;
  padding: 0 13px 0 13px;
  align-items: center;
  z-index: 1;
  margin-left: 20px;
  background: #fff;
  border-radius: 52px;
  box-shadow: 0px 2px 12px -9px rgba(0, 0, 0, 0.34);
}
.dropdown-container .dv-star-rating {
  margin-top: 7px;
}
.dropdown-container .selected {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 5px 0 5px;
}
.dropdown-container .selected span {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dropdown-container .dropdown-list {
  display: flex;
  width: 170px;
  justify-content: space-between;
  box-shadow: 0px 3px 9px -3px #c7c7c7;
  align-items: center;
  background: #fafafa;
  position: absolute;
  flex-direction: column;
  top: 30px;
  color: white;
  left: -1px;
  z-index: 2;
}
.dropdown-container .dropdown-list .dropdown-selection {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  z-index: 10;
  cursor: pointer;
}
.dropdown-container .dropdown-list .dropdown-selection:after {
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: block;
}
.dropdown-container .dropdown-list .dropdown-selection div {
  z-index: -1;
}
.dropdown-container .dropdown-list .highlight {
  background: #f1f1f1;
  z-index: 99;
}
.dropdown-container .dropdown-list .highlight .dv-star-rating-star {
  z-index: 0;
}
.Selectable
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(
    .DayPicker-Day--end
  ):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #9964f2;
}
.Selectable .DayPicker-Day {
  border-radius: 0 !important;
}
.Selectable .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
.Selectable .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(
    .DayPicker-Day--outside
  ) {
  background-color: #9964f2 !important;
}
.calendar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.calendar-container svg {
  margin: 0px 8px 0 0px;
}
.calendar-container button {
  margin: 0 8px 0 8px;
}
.calendar-container p {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-align: center;
  margin: 5px;
  white-space: nowrap;
}
.calendar-container .calendar-button {
  text-align: center;
  cursor: pointer;
}
.calendar-container .calendar-dates {
  text-align: center;
  padding: 4px 18px;
  cursor: pointer;
  font-size: 1rem;
  background: #fff;
  border-radius: 52px;
  box-shadow: 0px 2px 12px -9px rgba(0, 0, 0, 0.34);
}
.DayPicker {
  position: absolute !important;
  z-index: 9;
  box-shadow: 0px 3px 9px -3px #eaeaea;
  border-radius: 5px;
  left: 0;
  top: 40px;
  width: 490px;
}
.no-data-desc {
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-align: center;
  margin: 5px;
  white-space: nowrap;
  width: 100%;
}
.fa-calendar-alt {
  cursor: pointer;
}
.code-area-container {
  margin: 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.code-area-container hr {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  border: 1px solid #efefef;
}
.code-area-container .btn {
  width: 120px;
}
.code-area-container .col-12 {
  padding: 0;
}
.code-area-container .email-segment input {
  width: 450px;
  box-shadow: 0 2px 9px -2px #c7c7c7;
}
.code-area-container .email-segment .code-area-form-segment {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}
.code-area-container .email-segment .code-area-form-segment .btn {
  margin-left: 10px;
  margin-top: 0;
  height: 40px;
  width: 100px;
}
.code-area-container .email-segment .code-area-form-segment .form-group {
  margin: 0;
}
.code-area-container .email-segment .alert-success {
  position: relative;
}
.code-area-container .email-segment .email-alert-button {
  margin: 0;
  float: right;
  position: relative;
  bottom: 8px;
}
.code-area-container .code-segment {
  margin: 10px 0 10px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.code-area-container .code-segment .success-green {
  background: #4bb543;
}
.code-area-container .code-segment .btn {
  margin-left: 10px;
  height: 40px;
  width: 90px;
}
.text-area-code {
  width: 730px;
  background: transparent;
  border: none;
  height: 85px;
  margin: 5px;
  white-space: nowrap;
  height: 50px;
  font-style: italic;
  border: #c7c7c7;
}
.tab-selected {
  background: #e8e8e8;
  color: black;
  font-size: 1.1em;
}
.tab-default {
  background: #ffffff;
  color: black;
  font-size: 1.1em;
}
#nav-no-padding {
  padding: inherit;
}
.success-green {
  color: #4bb543;
  fill: #4bb543;
  cursor: pointer;
}
.icon-sizes {
  width: 15px;
  height: 15px;
}
.failure-red {
  color: red;
  fill: red;
  cursor: pointer;
}
.error-message-twilio {
  position: absolute;
  z-index: 9999999;
  width: 225px;
  padding: 15px;
  background: #fff;
  box-shadow: 0 4px 1px 0 rgba(219, 219, 219, 0.5);
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #616161;
  border-radius: 5px;
  white-space: normal;
}
.fa-star {
  margin: 0 3px;
}
.table-container {
  margin-bottom: 30px;
}
.sentiment {
  display: flex;
  justify-content: center;
}
.sentiment .sentiment-line-container {
  height: 25px;
  width: 600px;
  background: linear-gradient(to right, red, lawngreen);
  position: relative;
}
.temp {
  width: 35px;
  height: 35px;
  position: relative;
  bottom: 5px;
  transition: all 500ms ease-in-out;
}
.sentiment-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.error-red {
  color: #f37676;
}
.text-count {
  font-family: "Sofia-pro";
  font-size: 14px;
  color: #878787;
  margin-top: 5px;
  margin-bottom: 15px;
}
.review-controls {
  margin: 0px 16px;
  margin-bottom: 32px;
  width: 100%;
  justify-content: space-between;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 32px;
}
@media only screen and (max-width: 1024px) {
  .review-controls {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 16px;
  }
}
.review-controls .review-card-filter {
  box-shadow: 0px 3px 9px -3px #eaeaea;
  height: 36px;
}
.review-card-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 64px;
}
@media only screen and (max-width: 1024px) {
  .review-card-wrapper {
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (max-width: 768px) {
  .review-card-wrapper {
    grid-template-columns: 1fr;
  }
}
.review-card-wrapper .review-card-container {
  font-family: sofia-pro, sans-serif;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  position: relative;
  min-height: 148px;
  padding: 20px;
  border-radius: 5px;
  font-weight: 200;
  width: 100%;
}
.review-card-wrapper .review-card-container .review-widget-review {
  width: 100%;
  overflow: hidden;
  margin-bottom: 12px;
  margin-top: 12px;
  height: 84px;
  overflow-y: auto;
}
.review-card-wrapper .review-card-container .review-widget-head {
  width: 100%;
}
.review-card-wrapper
  .review-card-container
  .review-widget-head
  .review-person-name {
  float: left;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2em;
}
.review-card-wrapper .review-card-container .review-widget-head .review-phone {
  font-weight: 200;
  font-size: 12px;
  margin-top: 3px;
}
.review-card-wrapper
  .review-card-container
  .review-widget-head
  .star-container {
  float: right;
}
.review-card-wrapper
  .review-card-container
  .review-widget-head
  .star-container
  .dv-star-rating {
  height: 22px;
  float: right;
}
.review-card-wrapper .review-card-container .review-widget-head .review-status {
  text-align: right;
  font-size: 12px;
  margin-right: 3px;
}
.review-card-wrapper
  .review-card-container
  .review-widget-head
  .review-status.resent {
  color: #4bb543;
  font-weight: 500;
}
.review-card-wrapper
  .review-card-container
  .review-widget-head
  .review-status.filtered {
  color: #f37676;
  font-weight: 500;
}
.review-card-wrapper .review-card-container .review-widget-delivery-wrapper {
  width: 100%;
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
}
.review-card-wrapper
  .review-card-container
  .review-widget-delivery-wrapper
  .delivery-wrapper {
  display: flex;
}
.review-card-wrapper
  .review-card-container
  .review-widget-delivery-wrapper
  .delivery-wrapper
  img {
  width: 15px;
  margin-right: 8px;
  margin-top: 2px;
}
.review-card-wrapper
  .review-card-container
  .review-widget-delivery-wrapper
  .delivery-wrapper
  .resend-link {
  font-size: 12px;
  cursor: pointer;
}
.review-card-wrapper
  .review-card-container
  .review-widget-delivery-wrapper
  .delivery-wrapper
  .resend-link
  .accent {
  font-weight: 600;
}
.review-card-wrapper
  .review-card-container
  .review-widget-delivery-wrapper
  .delivery-wrapper
  .resend-link:hover
  .accent {
  color: #7939e7;
}
.review-card-wrapper
  .review-card-container
  .review-widget-delivery-wrapper
  .social-icons {
  min-width: 32px;
  text-align: right;
  margin-right: 2px;
}
.review-card-wrapper
  .review-card-container
  .review-widget-delivery-wrapper
  .social-icons
  .social-icon {
  filter: grayscale(1);
  opacity: 0.4;
}
.review-card-wrapper
  .review-card-container
  .review-widget-delivery-wrapper
  .social-icons
  .social-icon.link-clicked {
  filter: grayscale(0);
  opacity: 1;
}
.review-card-wrapper
  .review-card-container
  .review-widget-delivery-wrapper
  .social-icons
  img {
  width: 16px;
  height: 16px;
  float: right;
  margin-left: 6px;
  margin-top: 0px;
}
.organic-review-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 400px;
  top: 40%;
  transform: translateY(-40%);
}
.organic-review-wrapper h2,
.organic-review-wrapper p {
  text-align: center;
}
.organic-review-wrapper h2 {
  margin-bottom: 48px;
}
.organic-review-wrapper input {
  box-shadow: 0px 3px 9px -3px #eaeaea;
}
.organic-review-wrapper input:disabled {
  background: #eaeaea;
  color: #616161;
}
@media only screen and (max-width: 768px) {
  .organic-review-wrapper {
    left: 0;
    right: 0;
    position: relative;
    padding: 32px;
    transform: none;
  }
}
.organic-review-wrapper .form-wrapper {
  max-width: 600px;
  margin: auto;
}
.organic-review-wrapper .grid-2 {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
}
.organic-review-wrapper .buttons {
  margin-top: 48px;
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: center;
}
.route-reviews,
.route-new-review {
  background: #fafafa;
}
.route-reviews [class^="col-"],
.route-new-review [class^="col-"] {
  position: static;
}
.route-reviews .company-name,
.route-new-review .company-name {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100vw;
  font-weight: 700;
  text-align: center;
  z-index: 1000;
  font-size: 1.4rem;
  padding-top: 14px;
  user-select: none;
}
@media only screen and (max-width: 768px) {
  .route-reviews .company-name,
  .route-new-review .company-name {
    font-size: 1rem;
    padding-top: 19px;
  }
}
.route-reviews .app-banner,
.route-new-review .app-banner {
  display: none;
}
.route-reviews .app-container,
.route-new-review .app-container {
  padding-top: 60px;
}
.route-reviews .site-header,
.route-new-review .site-header {
  background: white;
}
.route-reviews .navbar,
.route-new-review .navbar {
  background: none;
  box-shadow: none;
  flex-wrap: wrap;
}
.route-reviews .navbar ul,
.route-new-review .navbar ul {
  display: none;
}
.route-reviews .navbar .col-6,
.route-new-review .navbar .col-6 {
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
  text-align: center;
}
.route-reviews .navbar .col-6:last-child,
.route-new-review .navbar .col-6:last-child {
  display: none;
}
.route-reviews .navbar .col-6 a,
.route-new-review .navbar .col-6 a {
  pointer-events: none;
  margin: 0;
}
.route-reviews .powered-by-wrapper,
.route-new-review .powered-by-wrapper {
  right: 32px;
  bottom: 0;
  height: 52px;
  position: absolute;
}
@media only screen and (max-width: 768px) {
  .route-reviews .powered-by-wrapper,
  .route-new-review .powered-by-wrapper {
    height: 64px;
    position: fixed;
    padding-top: 23px;
    background: #fafafa;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    justify-content: center;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
}
.route-reviews .powered-by-wrapper > div,
.route-new-review .powered-by-wrapper > div {
  display: flex;
}
@media only screen and (max-width: 768px) {
  .route-reviews .powered-by-wrapper > div,
  .route-new-review .powered-by-wrapper > div {
    width: fit-content;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
}
.route-reviews .powered-by-wrapper > div > div,
.route-new-review .powered-by-wrapper > div > div {
  margin-right: 6px;
  opacity: 0.3;
  line-height: 1em;
}
.route-reviews .powered-by-wrapper img,
.route-new-review .powered-by-wrapper img {
  width: 96px;
  filter: invert(1);
  opacity: 0.3;
  justify-content: center;
}
.rating-container,
.organic-review-wrapper {
  text-align: center;
  width: 100%;
  margin: auto;
  max-width: 760px;
  padding-left: 20px;
  padding-right: 20px;
  position: absolute;
  top: 40%;
  transform: translateY(-40%);
  left: 0;
  right: 0;
}
@media only screen and (max-width: 768px) {
  .rating-container,
  .organic-review-wrapper {
    transform: none;
    position: relative;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 64px;
    top: 0;
  }
}
.rating-container .btn:not(.btn-primary),
.organic-review-wrapper .btn:not(.btn-primary) {
  background: #eaeaea;
  color: #616161;
}
.rating-container .btn:hover,
.organic-review-wrapper .btn:hover {
  color: #616161;
}
.rating-container p,
.organic-review-wrapper p {
  margin-bottom: 40px;
  font-size: 24px;
}
@media only screen and (max-width: 768px) {
  .rating-container p,
  .organic-review-wrapper p {
    font-size: 20px;
  }
}
.rating-container textarea,
.organic-review-wrapper textarea {
  box-shadow: 0px 3px 9px -3px #eaeaea;
}
.rating-container .clipboard-container,
.organic-review-wrapper .clipboard-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}
.rating-container .disclaimer,
.organic-review-wrapper .disclaimer {
  font-size: 15px;
  margin-top: 30px;
}
.rating-container .back-button,
.organic-review-wrapper .back-button {
  margin-top: 14px;
  cursor: pointer;
  color: #aaa;
}
.rating-container .back-button:hover,
.organic-review-wrapper .back-button:hover {
  text-display: underline;
  color: #616161;
}
.rating-container-alternate {
  max-width: 760px;
  padding-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.platforms-list {
  max-width: 300px;
  margin: auto;
  padding: 0;
  margin-top: 40px;
}
.platforms-list img {
  width: 41px;
  padding: 10px;
  background: #fff;
  display: inline-block;
  border-radius: 3px;
  -webkit-box-shadow: 0 2px 9px -2px #c7c7c7;
  box-shadow: 0 2px 9px -2px #c7c7c7;
  margin-right: 10px;
  position: absolute;
  left: 0;
  top: 0;
}
.platforms-list li {
  list-style-type: none;
  margin-bottom: 20px;
}
.platforms-list li a {
  position: relative;
  background: #fff;
  display: block;
  font-weight: 500;
  padding: 10px 20px;
  border-radius: 3px;
  color: #165283;
}
.ratings-list {
  margin: 0;
  padding: 0;
  text-align: center;
}
.ratings-list li {
  display: inline-block;
  margin: 0 16px;
}
@media screen and (max-width: 600px) {
  .ratings-list li {
    margin: 0 10px;
  }
  .ratings-list li img {
    width: 40px !important;
    height: 40px !important;
  }
}
.ratings-list li a {
  position: relative;
  display: block;
}
.ratings-list li:hover a {
  transform: translateY(-5px);
  opacity: 1;
}
.ratings-list li span {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  -moz-transform: translateY(-50%) translateX(-50%);
  -ms-transform: translateY(-50%) translateX(-50%);
  -o-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  z-index: 2;
  color: #616161;
  font-weight: 500;
  line-height: 1;
  font-size: 18px;
}
@media screen and (max-width: 600px) {
  .ratings-list li span {
    font-size: 15px;
  }
}
.ratings-list li img {
  width: 60px;
  height: 60px;
  transition: all 300ms ease-in-out;
}
.platform-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  margin: auto;
  transform: translateY(-50%);
}
.clipboard-container {
  max-width: 400px;
  margin: 0 auto;
}
.clipboard-container p {
  font-size: 14px;
  margin-bottom: 16px;
  font-weight: 600;
}
.clipboard-container input {
  background-color: #fff !important;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  font-weight: 200;
}
.clipboard-container button {
  border: none;
  background: #9964f2;
  color: #fff;
  padding-left: 18px;
  padding-right: 18px;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}
.clipboard-container button span {
  margin-right: 8px;
}
.route-login,
.route-register {
  background-image: linear-gradient(-223deg, #a776f6 0%, #7332e4 100%);
  height: 100vh;
}
.route-login .site-header,
.route-register .site-header {
  background: none;
  box-shadow: none;
}
.route-login .app-container,
.route-register .app-container {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  left: 0;
  right: 0;
  margin: auto;
}
.settings-status-container {
  margin-bottom: 30px;
  margin-top: -54px;
}
.settings-status-container h3 {
  margin-bottom: 0;
  font-size: 20px;
  text-align: right;
}
.settings-status-container h3 span {
  display: inline-block;
  padding: 6px 12px 8px 12px;
  font-size: 14px;
  color: #fff;
  border-radius: 30px;
  margin-left: 10px;
}
.settings-status-container h3 span.active {
  background: #40de98;
}
.settings-status-container h3 span.trialing {
  background: #40de98;
}
.settings-status-container h3 span.inactive {
  background: #ff7b7b;
}
.default-message-header {
  display: flex;
  justify-content: space-between;
}
.form-page {
  box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 3px;
  background-color: #fff;
  padding: 30px;
}
.form-page h3 {
  margin-bottom: 20px;
}
.form-page input {
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
}
.form-page button {
  margin-top: 20px;
}
.form-page .user-info-container {
  padding: 0;
}
.form-page .user-info-container input {
  background: #fff;
}
.form-page .user-info-container #card-element {
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 0.25rem;
}
.form-page .active-subscription {
  box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 3px;
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ececec;
}
.form-page .active-subscription p {
  margin-bottom: 14px;
}
.form-page .active-subscription .btn {
  margin-top: 0px;
}
.form-page .active-subscription *:last-child {
  margin-bottom: 0;
}
.settings-nav {
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
  position: relative;
  z-index: 2;
}
.settings-nav li {
  display: inline-block;
}
.settings-nav li + li {
  margin-left: 20px;
}
.settings-nav a {
  display: block;
  color: #686868;
  padding-bottom: 5px;
  border-bottom: 3px solid transparent;
  font-size: 16px;
}
.settings-nav a.active {
  border-color: #686868;
}
.btn {
  padding-left: 22px;
  padding-right: 22px;
  outline: 0 !important;
  background: #9964f2;
  font-weight: 300;
  border: none;
  color: #fff;
  border-radius: 4px;
  line-height: 2;
  vertical-align: initial;
}
.btn.btn-disconnect {
  background: #efefef;
  color: #5f5f5f;
}
.btn.btn-disabled {
  background: #dedede !important;
  color: #fff !important;
}
.btn.btn-basic {
  background: none !important;
  color: #619bf9 !important;
}
.btn.btn-small {
  padding: 0;
  background: none;
  color: #686868 !important;
  font-size: 12px;
}
.btn:hover {
  color: #fff;
}
.btn svg {
  margin-right: 6px;
}
.btn-inverse {
  background: #fff;
  color: #2a2a2a;
}
.btn-inverse:hover {
  color: #aaa;
}
.btn-delete {
  background: none;
  color: #afafaf;
}
.btn-delete:hover {
  color: #6e6e6e;
}
.btn-fa {
  padding-left: 22px;
  padding-right: 22px;
  outline: 0 !important;
  background: #9964f2;
  font-weight: 300;
  border: none;
  color: #9964f2;
  border-radius: 4px;
  line-height: 2;
  vertical-align: initial;
  background: transparent;
}
.btn-fa.btn-fa-disconnect {
  color: #5f5f5f;
}
.btn-fa.btn-fa-disabled {
  color: #d3d3d3 !important;
}
.btn-fa.btn-fa-basic {
  color: #619bf9 !important;
}
.btn-fa svg {
  margin-right: 6px;
}
.card {
  box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 3px;
  background-color: #fff;
}
.card .card-header,
.card .card-footer {
  background: none;
  border-color: #efefef;
}
.card .card-header h3,
.card .card-footer h3 {
  margin: 0;
  padding: 0;
  line-height: 2;
}
.card h3.subtitle {
  text-transform: uppercase;
  font-size: 14px;
  color: #c5c5c5;
}
.card .card-header + .card-block {
  padding-top: 0;
}
.card .card-title {
  text-transform: uppercase;
  font-size: 16px;
  color: #939598;
  display: inline-block;
  width: auto;
}
.card .card-controls {
  float: right;
  position: relative;
}
.card .card-controls > * {
  display: inline-block;
  border: none !important;
  box-shadow: none !important;
}
.card .card-controls > * + * {
  margin-left: 10px;
}
.card .card-controls a {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
}
.card .card-controls select {
  border-radius: 3px;
  width: inherit;
  text-align: center;
  border-color: #efefef;
  -webkit-appearance: none;
  padding-left: 20px;
  padding-right: 20px;
  text-align-last: center;
  height: auto;
  font-size: 12px;
  background: #aaa;
  color: #2a2a2a;
}
.loader {
  position: absolute;
  margin: 60px auto;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.loader #outline {
  stroke: #9964f2;
  stroke-dasharray: 2.42777px, 242.77666px;
  stroke-dashoffset: 0;
  animation: anim 1.6s linear infinite;
}
.loader #outline-bg {
  stroke: #9964f2;
}
@keyframes anim {
  12.5% {
    stroke-dasharray: 33.98873px, 242.77666px;
    stroke-dashoffset: -26.70543px;
  }
  43.75% {
    stroke-dasharray: 84.97183px, 242.77666px;
    stroke-dashoffset: -84.97183px;
  }
  100% {
    stroke-dasharray: 2.42777px, 242.77666px;
    stroke-dashoffset: -240.34889px;
  }
}
.account-creation-loader .loader {
  position: relative;
  margin: 0;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 60px;
}
.account-creation-loader .loader #outline {
  stroke-dasharray: 2.42777px, 242.77666px;
  stroke-dashoffset: 0;
  stroke: white;
  animation: anim 1.6s linear infinite;
}
.account-creation-loader .loader #outline-bg {
  stroke: white;
}
@keyframes anim {
  12.5% {
    stroke-dasharray: 33.98873px, 242.77666px;
    stroke-dashoffset: -26.70543px;
  }
  43.75% {
    stroke-dasharray: 84.97183px, 242.77666px;
    stroke-dashoffset: -84.97183px;
  }
  100% {
    stroke-dasharray: 2.42777px, 242.77666px;
    stroke-dashoffset: -240.34889px;
  }
}
.spinner {
  width: 40px;
  height: 40px;
  margin: 20px auto;
  background-color: #9964f2;
  border-radius: 100%;
  -webkit-animation: sk-scaleout 1s infinite ease-in-out;
  animation: sk-scaleout 1s infinite ease-in-out;
}
@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}
@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
.panel {
  box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 3px;
  background-color: #fff;
}
.panel-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #efefef;
  padding: 1em;
}
.panel-title {
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #939598;
  width: auto;
}
.panel-controls a {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
}
.panel-controls select {
  border-radius: 3px;
  width: inherit;
  text-align: center;
  border-color: #efefef;
  -webkit-appearance: none;
  padding-left: 20px;
  padding-right: 20px;
  text-align-last: center;
  height: auto;
  font-size: 12px;
  color: #2a2a2a;
}
.panel-subtitle {
  text-align: center;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  text-transform: uppercase;
  color: #686868;
  font-size: 14px;
  height: 3em;
}
.name-badge-container {
  box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 3px;
  background-color: #fff;
  padding: 10px 14px;
  overflow: auto;
  margin-bottom: 30px;
  display: block;
  position: relative;
  background: #fff;
}
.clients .name-badge-container,
.home .name-badge-container {
  padding-right: 80px;
}
.clients .name-badge-container h2,
.clients .name-badge-container h3,
.home .name-badge-container h2,
.home .name-badge-container h3 {
  text-align: left;
}
.name-badge-container .name {
  margin-bottom: 0;
  font-size: 18px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 7px;
}
.name-badge-container .subtitle {
  font-size: 14px;
  color: #c5c5c5;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 300;
  margin-top: 5px;
}
.name-badge-container .btn {
  margin-left: 20px;
  position: relative;
  float: right;
  top: 7px;
}
.name-badge-container:hover {
  background: #fff !important;
  box-shadow: 0 11px 15px -5px rgba(0, 0, 0, 0.1);
}
.name-badge-container .manager-profile {
  text-align: center;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}
.name-badge-container .manager-profile:hover .profile-image-name {
  opacity: 1;
  visibility: visible;
}
.name-badge-container .manager-profile span,
.name-badge-container .manager-profile img {
  display: inline-block;
}
.name-badge-container .manager-profile span.profile-image--initials,
.name-badge-container .manager-profile img.profile-image--initials {
  color: #fff;
  text-transform: uppercase;
  line-height: 35px;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  font-size: 13px;
}
.name-badge-container .manager-profile .profile-image {
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  vertical-align: middle;
  width: 40px;
  height: 40px;
}
.name-badge-container .manager-profile .profile-image-name {
  color: #c5c5c5;
  margin-left: 10px;
  font-size: 13px;
  opacity: 0;
  padding: 8px 20px;
  background: #fff;
  position: absolute;
  right: 26px;
  line-height: 1;
  top: 50%;
  visibility: hidden;
  border-radius: 30px;
  box-shadow: 0px 0px 15px -6px #000;
  transform: translateY(-50%);
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.site-header {
  position: relative;
  z-index: 101;
  box-shadow: 0px 0px 22px -11px #000;
  background: #9964f2;
}
.route-home .site-header {
  background: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: none;
}
.site-header .navbar {
  padding-top: 0;
  padding-bottom: 0;
}
.site-header .navbar .header-logo {
  display: block;
  position: relative;
  padding: 20px 15px;
  width: 140px;
  top: 2px;
}
.site-header .navbar .header-current-location {
  border-left: 1px solid #d6d6d6;
  display: inline-block;
  color: #fff;
  padding-left: 20px;
  font-weight: 500;
  font-size: 16px;
  position: relative;
  top: 3px;
}
.site-header .navbar .header-branding {
  position: relative;
  display: inline-block;
  margin-left: -15px;
  margin-top: 0;
  margin-bottom: 0;
  padding: 18px 22px 18px 22px;
}
.site-header .navbar .header-branding img {
  height: 22px;
}
.site-header .navbar nav {
  text-align: right;
}
.site-header .navbar nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.site-header .navbar nav ul li {
  display: inline-block;
}
.site-header .navbar nav ul a {
  display: block;
  padding: 5px 18px;
  text-decoration: none;
  color: #fff;
  font-family: "sofia-pro";
  font-weight: 200;
  border-radius: 4px;
}
.site-header .navbar nav ul a.active {
  color: #fff;
}
.site-header .navbar nav > ul > li {
  position: relative;
}
.site-header .navbar nav > ul > li:hover > ul {
  display: block;
}
.site-header .navbar nav > ul > li > ul {
  position: absolute;
  display: none;
  left: 0;
  background: #fff;
  min-width: 100%;
  border: 1px solid #ececec;
  border-top: none;
  z-index: 99;
}
.site-header .navbar nav > ul > li > ul li {
  display: block;
  text-align: left;
}
.site-header .navbar nav > ul > li > ul a {
  padding-top: 10px;
  padding-bottom: 10px;
  color: #4b4b4b;
}
.site-header .navbar nav .user-link {
  padding-right: 24px;
  position: relative;
  color: #fff;
}
.site-header .navbar nav .user-link img {
  height: 24px;
  border-radius: 100%;
  margin-right: 10px;
}
.site-header .navbar nav .user-link:before {
  content: " ";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  background: url("../images/icon-dropdown.png") no-repeat;
  background-size: contain;
  width: 13px;
  height: 7px;
}
.pointer {
  cursor: pointer;
}
.visitPageButtons {
  color: #040208;
  margin: 5px;
  width: 175px;
  height: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 1.1em;
}
.visitPageButtons:hover {
  color: #7332e4;
}
.visitPagesContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.searchbar {
  display: inline-block;
  width: 100%;
  height: auto;
  padding: 9px 4px;
  padding-left: 33px;
  position: relative;
  border-radius: 22px;
  background: #fff url(../images/icon-search.png) left 11px center no-repeat;
  background-size: 14px 14px;
  border: none;
  box-shadow: 0 1px 17px -4px rgba(0, 0, 0, 0.1);
}
.statistics-bar > *:not(:last-child) {
  border-bottom: 1px solid #efefef;
}
.statistics-bar .statistics-bar-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.75em 0;
}
.statistics-bar .value {
  font-size: 1.5em;
  font-weight: 500;
  color: #686868;
  padding: 0.33em 0;
}
.statistics-bar .change {
  font-weight: 500;
}
@media (min-width: 800px) {
  .statistics-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
@media (min-width: 1024px) {
  .statistics-bar > *:not(:last-child) {
    border-right: 1px solid #efefef;
    border-bottom: none;
  }
}
.statistics-bar__subtitle {
  padding-left: 1em;
  font-size: 18px;
  color: #686868;
}
.responsive-table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
}
.responsive-thead {
  border-bottom: 3px solid #efefef;
  color: #686868;
}
.responsive-tr {
  background: #fff;
  padding: 0.35em;
  cursor: pointer;
}
.responsive-tr--subitem {
  background: #f5f5f5;
  font-size: 0.8em;
}
.responsive-th,
.responsive-td {
  padding: 0.625em;
}
.responsive-th {
  font-size: 0.85em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-weight: bold;
}
@media screen and (max-width: 800px) {
  .responsive-table {
    border: 0;
    table-layout: fixed;
  }
  .responsive-thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .responsive-tr {
    display: block;
    margin-bottom: 0.625em;
  }
  .responsive-td {
    display: block;
    font-size: 0.8em;
    text-align: right;
  }
  .responsive-td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
}
.location-link {
  box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 3px;
  background-color: #fff;
  padding: 20px 14px;
  overflow: auto;
  margin-bottom: 30px;
  display: block;
  position: relative;
  background: #fff !important;
  text-align: left;
}
.location-link:hover {
  background: #fff !important;
  box-shadow: 0 11px 15px -5px rgba(0, 0, 0, 0.1);
}
.location-link .name {
  margin-bottom: 0;
  font-size: 18px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.location-link ul {
  margin: 0;
  padding: 0;
  margin-top: 10px;
}
.location-link li {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  position: relative;
}
.location-link li + li {
  margin-left: 4px;
}
.location-link li img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 10px;
  left: 0;
  right: 0;
  margin: auto;
}
.location-link .rating {
  position: absolute;
  right: 14px;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  padding: 0px 10px;
  border-radius: 20px;
  border: 2px solid #eaeaea;
  color: #424242;
}
.location-link .rating svg {
  display: inline-block;
  margin: auto;
  width: 11px;
  position: relative;
  top: 0px;
  margin-right: 4px;
}
.location-link .rating svg path {
  fill: #7332e4;
}
.app-banner {
  background: #4e337b;
}
@media only screen and (max-width: 768px) {
  .app-banner {
    display: none;
  }
}
.app-banner .row {
  padding-top: 16px;
  padding-bottom: 16px;
}
.app-banner h3 {
  color: #fff;
  margin-bottom: 0;
  font-size: 14px;
  text-align: center;
  font-weight: 500;
}
.app-banner h3 .banner-label {
  display: inline-block;
  padding: 4px 12px 6px 12px;
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.6px;
  background: linear-gradient(90deg, #9964f2 0%, #619bf9 100%);
  margin-right: 10px;
}
